export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>WHAT WE OFFER</h2>
          <p>
          We offer a complete range of hair services including haircuts & styling, coloring, eyebrow
        and eyelash tinting, perming, hair straightening, and a lot more.
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  
                  {/* <i className={d.icon}></i> */}
                  <img className="imagem"  width="300"  height="400" src={d.icon}></img>

                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>  
    </div>
  )
}
